<template>
  <div v-if="seller">
    <div class="py-2 flex flex-col">
      <div class="text-lg text-bold font-serif text-center border-b border-secondary-300 pb-2">
        <span v-if="checkBirthday()">Grattis på födelsedagen </span>
        <span v-else>Välkommen </span>
        {{ seller.name }}
      </div>
    </div>

    <div class="flex flex-wrap mt-3">
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" :to="'/sales/seller/' + seller._id" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="dollar-sign" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Din försäljning</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/admin/staff" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="id-card" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Personal</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/company-info" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="info-circle" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Info</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" :to="'/mina-avtal/seller/' + seller._id" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="file-alt" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Avtal</div>
        </router-link>
      </div>
      <div class="w-full lg:w-1/5 lg:pr-3">
        <router-link tag="div" to="/admin/kunder" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
          <BaseIcon icon="users" class="text-6xl" />
          <div class="font-semibold font-serif mt-3">Kunder</div>
        </router-link>
      </div>
    </div>

    <div class="flex flex-wrap mt-6">
      <div class="w-full lg:w-1/2 lg:pr-3 mt-2">
        <News />
      </div>
      <div class="w-full lg:w-1/2 lg:pr-3 mt-2">
        <Links />
      </div>
    </div>
  </div>
</template>

<script>
import News from "@/components/news";
import Links from "@/components/link";

export default {
  name: "DashboardSeller",
  title() {
    return `Säljare | SecurCloud`;
  },
  components: {
    News,
    Links,
  },
  data() {
    return {
      user: this.$store.state.user,
      seller_id: this.$route.params.id,
      seller: null,
    };
  },
  methods: {
    async getSellerDashboard() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/dashboard/seller/${this.seller_id}`);
        this.seller = response.data.seller;

        this.setPageTitle(`${this.seller.name} - Dashboard`, "seller_dashboard");
      } catch (error) {
        this.handleError(error);
      }
    },

    checkBirthday() {
      if (
        this.seller.birth_date &&
        this.moment()
          .startOf("day")
          .isSame(this.seller.birth_date, "day")
      )
        return true;
      else return false;
    },

    currency: function(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " kr";
    },
  },

  created() {
    this.getSellerDashboard();
  },
};
</script>
